
import bus from "@/utils/bus";
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  onUnmounted,
  nextTick,
  computed,
  watch
} from "vue";

import { titles } from "./dialog-install";
import { ElMessage } from "element-plus";

export default defineComponent({
  setup() {
    let compRef = ref(null);
    let dialogVisible = ref(false);
    let dataList: Ref = ref<Array<any>>([]);
    let dataRow: Ref<any> = ref(null);
    let submitting = ref(false);
    let width: Ref<any> = ref("50%");
    let currentComp = ref<string>("");

    onMounted(() => {
      bus.on("on-table-row-edit", async (data: any) => {
        let name = data.dataRow.defaultFormModelName.replace(
          "Model",
          "Edit"
        ) as string;

        dataList.value = data.dataList;
        dataRow.value = data.dataRow;

        dialogVisible.value = true;
        currentComp.value = name;
        console.log("=====================================显示的视图是", name);

        nextTick(() => {
          let component = compRef.value as any;
          component.setData(dataList, dataRow);
          if (component.dialogWidth) width.value = component.dialogWidth;
        });
      });
    });

    onUnmounted(() => {
      dialogVisible.value = false;
      bus.off("on-table-row-edit");
    });

    let clickSubmit = async () => {
      let component = compRef.value as any;
      submitting.value = true;
      let result = await component.submit();
      submitting.value = false;
      if (result) {
        dialogVisible.value = false;
        bus.emit("on-table-row-edit-ok", dataRow.value.id as number);
        ElMessage({
          message: dialogTitle.value + "成功",
          type: "success"
        });
      } else {
        ElMessage({
          message: dialogTitle.value + "失败",
          type: "warning"
        });
      }
    };

    let clickCancel = () => {
      dialogVisible.value = false;
    };

    let isEdit = computed(() => {
      let action = (compRef.value || ({} as any)).isEdit;
      return action;
    });

    let dialogTitle = computed(() => {
      let actionTitle =
        isEdit.value === true ? "编辑" : isEdit.value === false ? "新建" : "";
      return actionTitle + (titles as any)[currentComp.value];
    });

    watch(dialogVisible, () => {
      submitting.value = false;
    });

    return {
      width,
      compRef,
      submitting,
      dialogVisible,
      dataRow,
      dataList,
      clickCancel,
      clickSubmit,
      currentComp,
      isEdit,
      dialogTitle
    };
  }
});
